import React, { useState } from "react"
import { IoChevronForwardCircleOutline } from "react-icons/io5"
import { graphql, useStaticQuery } from "gatsby"
const query = graphql`
  {
    allStrapiEducation(sort: { fields: strapi_id, order: DESC }) {
      nodes {
        Course
        Date
        Institution
        Description {
          Content
          id
        }
      }
    }
  }
`
const Education = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiEducation: { nodes: educations },
  } = data
  const [value, setValue] = useState(0)
  const { Institution, Course, Date, Description } = educations[value]
  return (
    <section className="education__section">
      <div className="section-center">
        <div className="title__section--center">
          <h2>Education</h2>
          <div className="underline__title--resume"></div>
        </div>
        <div className="experience__container--center">
          <div className="education__btn--container">
            {educations.map((item, index) => {
              return (
                <button
                  key={index}
                  className={
                    index === value
                      ? "education__btn active-button"
                      : "education__btn"
                  }
                  onClick={() => setValue(index)}
                >
                  {item.Institution}
                </button>
              )
            })}
          </div>
          <article className="education__text">
            <h3>{Course}</h3>
            <h4>{Institution}</h4>
            <p className="resume__date">{Date}</p>
            {Description.map(item => {
              return (
                <div key={item.id} className="resume__description">
                  <IoChevronForwardCircleOutline className="education__icon" />
                  <p>{item.Content}</p>
                </div>
              )
            })}
          </article>
        </div>
      </div>
    </section>
  )
}

export default Education

import React from "react"
import Layout from "../components/Layout"
import Experiences from "../components/Experience"
import Education from "../components/Education"
import Technologies from "../components/Technologies"
import "../styles/resume.css"
import "../styles/main.css"
// ...GatsbyImageSharpFluid

const ResumePage = () => {
  return (
    <Layout
      title="Resume | Rayane Silva"
      description="More information about my career and education"
    >
      <section className="resume-page">
        <div className="resume-section">
          <Experiences />
          <Education />
          <Technologies />
        </div>
      </section>
    </Layout>
  )
}

export default ResumePage

export default [
  {
    id: 1,
    title: "HTML/CSS",
    level: "90",
  },
  {
    id: 2,
    title: "Node.js",
    level: "88",
  },
  {
    id: 3,
    title: "Javascript",
    level: "90",
  },
  {
    id: 4,
    title: "SQL",
    level: "75",
  },
  {
    id: 5,
    title: "React",
    level: "80",
  },
  {
    id: 6,
    title: "GraphQL",
    level: "65",
  },
  {
    id: 7,
    title: "Vue.js",
    level: "75",
  },
  {
    id: 8,
    title: "Git",
    level: "85",
  },
]

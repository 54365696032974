import React, { useState, useEffect } from "react"

const Progress = ({ value }) => {
  const [style, setStyle] = useState({})
  useEffect(() => {
    const timer = setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${value}%`,
      }
      setStyle(newStyle)
    }, 200)
    return () => clearTimeout(timer)
  }, [value])

  return (
    <div className="progress-bar">
      <div className="progress-bar__value" style={style}></div>
    </div>
  )
}

export default Progress

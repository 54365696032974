import technologies from "../constants/technologies"
import Progress from "./Progress"
import React from "react"

const Technologies = () => {
  return (
    <section className="section tech__section">
      <div className="title__section--center">
        <h2>Skills</h2>
        <div className="underline__title--resume"></div>
      </div>
      <div className="section-center tech__container">
        {technologies.map(tech => {
          const { id, title, level } = tech
          return (
            <article key={id} className="tech__progress-bar">
              <h3>{title}</h3>
              <Progress value={level} />
            </article>
          )
        })}
      </div>
    </section>
  )
}

export default Technologies
